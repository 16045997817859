/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import {
  setODR,
  useMaterialUIController,
  setOpenDrawerRight,
  setCurrentLawyerStatusId,
  setCurrentCertificateStatusId,
  setCircularProgress,
  setOfficeInfo,
} from "context";
import { getAllLawyerOffices } from "api/api_lawyer";
import { Grid, Icon } from "@mui/material";
// import Billing from "layouts/billing";

function Certificate({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  const { officeInfo } = controller;
  const [currentTable, setCurrentTable] = useState([]);
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAllLawyerOffices((isOk, data) => {
      if (!isOk) {
        console.log("get lawyer offices from api is not working!");
        setCircularProgress(dispatch, false);
      } else {
        setCurrentTable(data.data);
        setCircularProgress(dispatch, false);
      }
    }, id);
  }, [officeInfo]);
  const getStatusColor = (value) => {
    switch (value) {
      case "accepted":
        return "bg-[#17d41a]";
      case "denied":
        return "bg-[#780b01]";
      case "pending":
        return "bg-[#bfd611]";
      case "pending_office":
        return "bg-[#634c01]";
      default:
        return "bg-[#000d07]";
    }
  };
  return (
    <MDBox
      pb={3}
      style={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <div className="py-4 px-8 w-[100%]">
        <Grid container pt={1} pb={2} px={2}>
          {currentTable.length > 0 &&
            currentTable.map((value, index) => (
              <Grid
                item
                xs={6}
                key={index}
                style={{
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    boxShadow: "0 0 20px grey",
                    height: "300px",
                    // display: "flex",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <Grid container className="w-[100%] h-[10%] flex justify-around align-center">
                    <Grid xs={4} className="px-4 text-[12px] flex items-center justify-center">
                      #{value.id}
                    </Grid>
                    <Grid
                      xs={4}
                      className={`px-4 ${getStatusColor(
                        value.status
                      )} text-[12px] rounded-br-full text-white rounded-bl-full flex items-center justify-center`}
                    >
                      {value.status && value.status[0].toUpperCase()}
                      {value.status && value.status.substring(1)}
                    </Grid>
                    <Grid xs={4} className="px-4 text-[15px] flex justify-end items-center">
                      <Icon
                        onClick={() => {
                          setCurrentLawyerStatusId(dispatch, id);
                          setCurrentCertificateStatusId(dispatch, value.id);
                          setOfficeInfo(dispatch, JSON.stringify(value));
                          setODR(dispatch, "editOfficeLawyerInDetail");
                          setTimeout(() => {
                            setOpenDrawerRight(dispatch, true);
                          }, 1);
                        }}
                        className="cursor-pointer"
                      >
                        edit
                      </Icon>
                    </Grid>
                  </Grid>
                  <div className="w-[100%] h-[90%] items-end flex">
                    <div className="w-[40%] h-[100%] items-end flex flex-col">
                      <div className="w-[100%] h-[30%]" />
                      <div className="text-black mr-[10%] text-[16px] font-bold">{value.name}</div>
                      <div className="text-black mr-[10%] text-[12px] mt-[-10px]">
                        {value.state}
                      </div>
                      <div className="w-[100%] h-[80%] bg-black w-[10px] mr-[45%] " />
                    </div>
                    <div className="w-[10%] h-[100%] flex flex-col items-center justify-end">
                      <div className="h-[30px] w-[30px] bg-black flex items-center justify-center rounded-full">
                        <Icon style={{ color: "white" }}>location_on</Icon>
                      </div>
                      <div className="h-[15%] w-[2px] bg-black" />
                      <div className="h-[30px] w-[30px] bg-black flex items-center justify-center rounded-full">
                        <Icon style={{ color: "white" }}>phone</Icon>
                      </div>
                      <div className="h-[15%] w-[2px] bg-black" />
                      <div className="h-[30px] w-[30px] bg-black flex items-center justify-center rounded-full">
                        <Icon style={{ color: "white" }}>location_city</Icon>
                      </div>
                      <div className="h-[25%] w-[2px] bg-black" />
                    </div>
                    <div className="w-[50%] h-[100%] flex flex-col justify-end">
                      <div className="h-[30px] text-[13px] w-[100%] flex items-center justify-center rounded-full">
                        {value.address}
                      </div>
                      <div className="h-[15%]" />
                      <div className="h-[30px] text-[13px] w-[100%] flex items-center justify-center rounded-full">
                        {value.phone}
                      </div>
                      <div className="h-[15%]" />
                      <div className="h-[30px] text-[13px] w-[100%] flex flex-col items-center justify-center rounded-full">
                        <div>{value.city}</div>
                        <div>{value.post_code}</div>
                      </div>
                      <div className="h-[25%]" />
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
    </MDBox>
  );
}

export default Certificate;
