import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import SearchIcon from "@mui/icons-material/Search";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setSearchBarValueDetailLive,
  setSearchBarValueDetailCase,
  setSearchBarValueDetailPayment,
  setSearchBarValueTransaction,
  setSearchBarValueSession,
  setCircularProgress,
  setPersonInfo,
  setClientTab,
} from "context";
import MDInput from "components/MDInput";
import Navbar from "layouts/clientDetails/UserProfile/Navbar";
import { getAClientDetails } from "api/api_client";
// import { Divider } from "@mui/material";
import SettingMenu from "components/Setting";

function ClientDetailNavbar({ absolute, light, isMini }) {
  const [searchBarValue, setSearchBarValue] = useState("");
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    // openConfigurator,
    darkMode,
    clientTab,
    personInfo,
    scrollInDetail,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const currentId = useLocation().pathname.split("/")[useLocation().pathname.split("/").length - 1];
  const routesList = ["/clients", `/clients/detail/${currentId}`];

  useEffect(() => {
    setSearchBarValue("");
  }, [clientTab]);
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const submitSearchBox = (e) => {
    e.preventDefault();
    if (clientTab === 3) {
      setSearchBarValueDetailLive(dispatch, searchBarValue);
    }
    if (clientTab === 4) {
      setSearchBarValueDetailCase(dispatch, searchBarValue);
    }
    if (clientTab === 5) {
      setSearchBarValueDetailPayment(dispatch, searchBarValue);
    }
    if (clientTab === 6) {
      setSearchBarValueTransaction(dispatch, searchBarValue);
    }
    if (clientTab === 8) {
      setSearchBarValueSession(dispatch, searchBarValue);
    }
  };
  useEffect(() => {
    if (clientTab === 3) {
      setSearchBarValueDetailLive(dispatch, searchBarValue);
    }
    if (clientTab === 4) {
      setSearchBarValueDetailCase(dispatch, searchBarValue);
    }
    if (clientTab === 5) {
      setSearchBarValueDetailPayment(dispatch, searchBarValue);
    }
    if (clientTab === 6) {
      setSearchBarValueTransaction(dispatch, searchBarValue);
    }
    if (clientTab === 8) {
      setSearchBarValueSession(dispatch, searchBarValue);
    }
  }, []);
  const setTabFunction = (num) => {
    setClientTab(dispatch, num);
  };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAClientDetails((isOk, data) => {
      if (!isOk) {
        console.log("get client details from api is not working!");
        navigate("/clients?page=1");
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setPersonInfo(dispatch, data);
      }
    }, id);
  }, []);
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs
            icon="home"
            title={`${personInfo.first_name} ${personInfo.last_name}`}
            route={route}
            routesList={routesList}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {(clientTab === 3 ||
              clientTab === 4 ||
              clientTab === 5 ||
              clientTab === 6 ||
              clientTab === 8) && (
              <>
                <MDBox
                  mr={1}
                  onClick={(e) => submitSearchBox(e)}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <SearchIcon />
                </MDBox>
                <MDBox component="form" onSubmit={(e) => submitSearchBox(e)} role="form" pr={1}>
                  <MDInput
                    label="Search here"
                    value={searchBarValue}
                    onChange={(e) => setSearchBarValue(e.target.value)}
                  />
                </MDBox>
              </>
            )}
            <MDBox color={light ? "white" : "inherit"} className="flex">
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className="mr-3" sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <Link to="/profile" className="flex">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <SettingMenu />
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      {scrollInDetail >= 322 && (
        <div>
          {/* <Divider className="mt-2" /> */}
          <div className={` transition-all flex items-center justify-center`}>
            <Navbar tab={clientTab} setTab={setTabFunction} />
          </div>
        </div>
      )}
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
ClientDetailNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
ClientDetailNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default ClientDetailNavbar;
