import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import { useMaterialUIController, setCircularProgress } from "context";
import { useState, useEffect } from "react";
// import person from "assets/images/person.png";
import { Icon } from "@mui/material";
import { getDownloadLinkOfLive } from "api/api_lives";
// import { useNavigate } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { clientLiveDetails } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(clientLiveDetails);
  }, [clientLiveDetails]);

  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  const getDownloadLink = (id) => {
    setCircularProgress(dispatch, true);
    getDownloadLinkOfLive((isOk, result) => {
      if (!isOk) {
        console.log("get clients Lives List from api is not working", result);
        setCircularProgress(dispatch, false);
      } else {
        window.open(result.url, "_blank", "noopener, noreferrer");
        setCircularProgress(dispatch, false);
      }
    }, id);
  };
  const getIsoString = (date) => {
    let currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    currentDate = currentDate.toISOString();
    return currentDate;
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      lawyer: (
        <div>
          {value.demands.length === 0 && "no lawyer"}
          {value.demands.map((val) => (
            <div className="text-[13px]" key={val.id}>
              {getFullName(val?.lawyer?.first_name, val?.lawyer?.last_name)}
            </div>
          ))}
        </div>
      ),
      private: (
        <MDBox lineHeight={1} textAlign="center">
          {!value.visibility ? "YES" : "NO"}
        </MDBox>
      ),
      status: (
        <MDBox
          ml={-1}
          color={value.status === "active" ? "success" : "dark"}
          variant="gradient"
          size="small"
        >
          {value.status === "active" ? "in progress" : "ended"}
        </MDBox>
      ),
      "room name": (
        <MDBox lineHeight={1} textAlign="center">
          {value.room_name}
        </MDBox>
      ),
      "created at": (
        <MDBox lineHeight={1} textAlign="center">
          {getIsoString(value.created_at).split("T")[0]}
          <div style={{ marginTop: "5px" }}>
            {getIsoString(value.created_at).split("T")[1].split(".")[0]}
          </div>
        </MDBox>
      ),
      category: value.category ? (
        <MDBox lineHeight={1} textAlign="center">
          {value.category.name}
        </MDBox>
      ) : (
        <MDBox lineHeight={1} textAlign="center" style={{ color: "red" }}>
          Deleted Category
        </MDBox>
      ),
      download: (
        <MDBox className="flex items-center justify-center" textAlign="center">
          <div onClick={() => getDownloadLink(value.id)} role="presentation">
            <Icon fontSize="medium" className="text-[#3790ED] cursor-pointer">
              download
            </Icon>
          </div>
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "lawyer", accessor: "lawyer", align: "left" },
      { Header: "private", accessor: "private", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "room name", accessor: "room name", align: "center" },
      { Header: "start live", accessor: "created at", align: "center" },
      { Header: "category", accessor: "category", align: "center" },
      { Header: "download", accessor: "download", align: "center" },
    ],

    rows: getRows(),
  };
}
