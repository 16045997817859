import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Icon,
} from "@mui/material";
import { useMaterialUIController, setOpenMoreInfoLiveModal } from "context";
import React, { useEffect, useState } from "react";
import { getLiveInfo } from "api/api_lives";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";

function Modal({ modalMode, setModalMode }) {
  const [controller, dispatch] = useMaterialUIController();
  const { openMoreLiveInfoModal, liveId } = controller;
  const [liveDetail, setLiveDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rtmpUrl, setRtmpUrl] = useState("");
  useEffect(() => {
    if (liveId !== -1 && openMoreLiveInfoModal) {
      setLoading(true);
      getLiveInfo((isOk, data) => {
        if (!isOk) {
          console.log("get all Cases List from api is not working", data);
          setLoading(false);
        } else {
          setLiveDetail(data);
          setRtmpUrl(`rtmp://dev-lawyersarmy.diyacotech.com:1935/live/${data.room_name}`);
          setLoading(false);
        }
      }, liveId);
    }
  }, [liveId, openMoreLiveInfoModal]);
  const handleClose = () => setOpenMoreInfoLiveModal(dispatch, false);
  const getTextColor = (value) => {
    switch (value) {
      case "accepted":
        return "text-[#1C810C]";
      case "accepted-by-client":
        return "text-[#1C810C]";
      case "accepted-by-lawyer":
        return "text-[#1C810C]";
      case "rejected":
        return "text-[#F47575]";
      case "rejected-by-lawyer":
        return "text-[#F47575]";
      case "rejected-by-client":
        return "text-[#F47575]";
      case "pending":
        return "text-[#bfd611]";
      default:
        return "text-[#000d07]";
    }
  };
  return (
    <div>
      <Dialog
        open={openMoreLiveInfoModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div className="flex">
            <div>{modalMode === 0 ? "More Info About This Live" : "Watching Live"}</div>
            <div className="ml-auto">
              <Icon className="cursor-pointer" onClick={handleClose}>
                close
              </Icon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box>
              {loading && <div>loading...</div>}
              {!loading && modalMode === 0 && (
                <div>
                  <div className="flex justify-center">
                    <div className="text-[12px] flex items-center">ID:&nbsp;&nbsp;</div>
                    <div className="border-b border-dashed grow flex mb-[10px]" />
                    <div className="text-black text-[14px] font-bold flex items-center">
                      {liveDetail.id}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="text-[12px] flex items-center">Room Name:&nbsp;&nbsp;</div>
                    <div className="border-b border-dashed grow flex mb-[10px]" />
                    <div className="text-black text-[14px] font-bold flex items-center">
                      {liveDetail.room_name}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="text-[12px] flex items-center">Status:&nbsp;&nbsp;</div>
                    <div className="border-b border-dashed grow flex mb-[10px]" />
                    <div className="text-black text-[14px] font-bold flex items-center">
                      {liveDetail.status}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="text-[12px] flex items-center">Visibility:&nbsp;&nbsp;</div>
                    <div className="border-b border-dashed grow flex mb-[10px]" />
                    <div className="text-black text-[14px] font-bold flex items-center">
                      {liveDetail.visibility === 0 ? "Private" : "Public"}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="text-[12px] flex items-center">Category:&nbsp;&nbsp;</div>
                    <div className="border-b border-dashed grow flex mb-[10px]" />
                    <div className="text-black text-[14px] font-bold flex items-center">
                      {liveDetail.category ? liveDetail?.category?.name : "Deleted Category"}
                    </div>
                  </div>
                  {liveDetail.users_count && (
                    <div className="flex justify-center">
                      <div className="text-[12px] flex items-center">Users count:&nbsp;&nbsp;</div>
                      <div className="border-b border-dashed grow flex mb-[10px]" />
                      <div className="text-black text-[14px] font-bold flex items-center">
                        {liveDetail.users_count}
                      </div>
                    </div>
                  )}
                  <Divider />
                  <div className="flex justify-center">
                    <div className="text-[16px] text-black font-bold flex items-center">
                      Users In This Live:
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="text-[15px] flex  text-black font-bold items-center">
                      {liveDetail.owner.first_name} {liveDetail.owner.last_name}{" "}
                      <span className="text-[13px] text-gray-500">&nbsp;&#40;Owner&#41;</span>
                    </div>
                    {liveDetail.demands.length !== 0 ? (
                      liveDetail.demands.map((value) => (
                        <div key={value.id} className="border p-2 rounded-md my-2">
                          <div className="text-[15px] text-black font-bold flex items-center">
                            {value?.lawyer?.first_name} {value?.lawyer?.last_name}
                            <span className="text-[13px] text-gray-500">
                              &nbsp;&#40;Lawyer&#41;:
                            </span>
                          </div>
                          <div className="flex">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="text-[12px]">Status: &nbsp;</div>
                            <div className={`text-[12px] ${getTextColor(value?.status)}`}>
                              {value?.status}
                            </div>
                          </div>
                          <div className="flex">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="text-[12px]">Duration: &nbsp;</div>
                            <div className="text-[12px]">{value?.duration}</div>
                          </div>
                          <div className="flex">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="text-[12px]">Date and Time: &nbsp;</div>
                            <div className="text-[12px]">{value?.date_and_time}</div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center justify-center">
                        There is no lawyer here
                      </div>
                    )}
                  </div>
                  <Divider />
                  <div className="flex items-center justify-center">
                    <div className="flex bg-[#0295E9] text-white font-bold px-2 rounded-md ">
                      <div
                        role="presentation"
                        onClick={() => {
                          setModalMode(1);
                        }}
                        className="text-[25px] flex cursor-pointer"
                      >
                        <Icon>play_arrow</Icon>
                      </div>
                      <div
                        role="presentation"
                        onClick={() => {
                          setModalMode(1);
                        }}
                        className="underline text-[16px] cursor-pointer"
                      >
                        Watch
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!loading && modalMode === 1 && (
                <div className="flex items-center justify-center">
                  <ReactPlayer
                    url={rtmpUrl}
                    width="500px"
                    height="300px"
                    controls
                    playing
                    config={{
                      file: {
                        forceFLV: true,
                      },
                    }}
                  />
                </div>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
Modal.propTypes = {
  modalMode: PropTypes.number,
  setModalMode: PropTypes.func,
};
Modal.defaultProps = {
  modalMode: 0,
  setModalMode: () => {},
};
export default Modal;
