import PropTypes from "prop-types";

function Navbar({ tab, setTab }) {
  const tabList = [
    { title: "Overview", id: 1 },
    { title: "Address", id: 2 },
    { title: "Lives", id: 3 },
    { title: "Cases", id: 4 },
    { title: "Payments", id: 5 },
    { title: "Transactions", id: 6 },
    { title: "Subscription", id: 7 },
    { title: "Sessions", id: 8 },
  ];
  return (
    <div className="flex flex-row">
      {tabList.map(({ title, id }) => (
        <div
          role="presentation"
          key={id}
          onClick={() => setTab(id)}
          className={`font-bold ${
            id === tab ? "border-b-2 border-[#0295E9] text-[#0295E9]" : "text-[#919090]"
          } hover:border-b-2 hover:border-[#0295E9] cursor-pointer mr-4 text-[16px]`}
        >
          {title}
        </div>
      ))}
    </div>
  );
}
Navbar.propTypes = {
  tab: PropTypes.number,
  setTab: PropTypes.func,
};
Navbar.defaultProps = {
  tab: 1,
  setTab: () => {},
};
export default Navbar;
