import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  setMobileInDrawerRight,
  setFirstNameInDrawerRight,
  setLastNameInDrawerRight,
  setBirthdayInDrawerRight,
  setEmailInDrawerRight,
  setGenderInDrawerRight,
  setOpenDrawerRight,
  setCreateOrUpdate,
  setODR,
  useMaterialUIController,
  setUpdateLawyerId,
  setLawyerStatusInDrawerRight,
  setCurrentLawyerStatusId,
  setCircularProgress,
  setCustomGenderInDrawerRight,
} from "context";

import { useNavigate } from "react-router-dom";
import verticalMenu from "../../../../assets/images/menu-dots-vertical-2.png";
import userAvatar from "../../../../assets/images/unkown-user.png";

export default function LawyerRow({ item }) {
  // eslint-disable-next-line no-unused-vars
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  useEffect(() => {
    if (!item) {
      setCircularProgress(dispatch, true);
    } else setCircularProgress(dispatch, false);
  }, [item]);

  const clickOnEdit = () => {
    setUpdateLawyerId(dispatch, item.id);
    setMobileInDrawerRight(dispatch, item.mobile);
    setFirstNameInDrawerRight(dispatch, item.first_name);
    setLawyerStatusInDrawerRight(dispatch, item.status);
    setLastNameInDrawerRight(dispatch, item.last_name);
    setBirthdayInDrawerRight(dispatch, item.birthday);
    setCustomGenderInDrawerRight(dispatch, item.custom_gender);
    setEmailInDrawerRight(dispatch, item.email);
    setCreateOrUpdate(dispatch, true);
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
    setGenderInDrawerRight(dispatch, item.sex);
    setODR(dispatch, "editOrCreate");
  };

  const statusIsClicked = (id) => {
    setODR(dispatch, "statusLawyer");
    setCurrentLawyerStatusId(dispatch, id);
    setFirstNameInDrawerRight(dispatch, item.first_name);
    setLawyerStatusInDrawerRight(dispatch, item.status);
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
  };

  if (!item) return <div>Loading</div>;

  return (
    <div
      className="w-full flex flex-row p-3 items-center justify-between gap-4 bg-white rounded-md shadow-md hover:shadow-lg"
      key={item.id}
    >
      <div className="text-[13px] w-[30px]">#{item.id}</div>
      <img
        className="w-[50px] h-[50px] rounded-full mr-3 object-cover"
        src={item?.avatars?.medium ? item?.avatars?.medium : userAvatar}
        alt=""
      />
      <div
        className="text-[16px] font-semibold w-[200px] hover:underline hover:cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={() => {
          navigate(`/lawyers/detail/${item.id}`);
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            navigate(`/lawyers/detail/${item.id}`);
          }
        }}
      >{`${item.first_name} ${item.last_name}`}</div>
      <div className="text-gray-500 text-[14px] text-left w-[200px]">{item.mobile}</div>
      <div className="text-gray-500 text-[14px] text-left w-[200px]">{item.email}</div>
      <div className="text-gray-500 text-[14px] text-left w-[100px]">{item.birthday}</div>
      <div
        className={`text-[14px] w-[80px] border-t-[1px] border-gray-300 text-center p-1 rounded-md ${
          item.status === "active" ? "bg-green-700 text-white" : "bg-red-700 text-white"
        } `}
        role="button"
        tabIndex={0}
        onClick={() => statusIsClicked(item.id)}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            statusIsClicked(item.id);
          }
        }}
      >
        {item.status}
      </div>
      <div
        className="cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={() => clickOnEdit(item)}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            clickOnEdit(item);
          }
        }}
      >
        <img src={verticalMenu} className="w-[25px] h-[20px]" alt="" />
      </div>
    </div>
  );
}

LawyerRow.propTypes = {
  item: PropTypes.any.isRequired,
};
