import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setLawyersData,
  setCircularProgress,
  setPersonInfo,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
// import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import MDInput from "components/MDInput";
import { updateLawyerCost, getLawyersList } from "api/api_lawyer";

function CostDrawerInDetail() {
  const [controller, dispatch] = useMaterialUIController();
  const { openDrawerRight, darkMode, currentLawyerInfo, personInfo } = controller;
  const [localCost, setLocalCost] = useState(0);
  const [localSessionCost, setLocalSessionCost] = useState(0);
  const [name, setName] = useState("");
  useEffect(() => {
    setLocalCost(currentLawyerInfo.cost_per_min);
    setLocalSessionCost(currentLawyerInfo.cost_per_session);
    setName(currentLawyerInfo.first_name);
  }, []);
  const saveCost = () => {
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    const body = {
      income_per_minute: localCost,
      cost_per_session: localSessionCost,
    };
    updateLawyerCost(
      (isOk, data) => {
        if (!isOk) {
          console.log("update cost api is not working", data);
          setAlertText(dispatch, "update cost lawyer is not working");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "error");
        } else {
          console.log(`cost of user with id: ${name} is updated`, data);
          setAlertText(dispatch, "update cost is successfull");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "success");
          getLawyersList((isOk2, data2) => {
            if (!isOk2) {
              console.log("get Lawyers data from api is not working", data2);
            } else {
              setLawyersData(dispatch, data2);
              setCircularProgress(dispatch, false);
              setPersonInfo(dispatch, {
                ...personInfo,
                cost_per_min: localCost,
                cost_per_session: localSessionCost,
              });
            }
          }, `${window.location.search}`);
        }
      },
      body,
      currentLawyerInfo.id
    );
  };
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit cost per min
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox mb={2}>
        <MDBox style={{ fontSize: "1rem" }}>Change {name}&apos;s cost per minute</MDBox>
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          value={localCost}
          onChange={(e) => setLocalCost(e.target.value)}
          type="number"
          label="Cost per min(cent)"
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          value={localSessionCost}
          onChange={(e) => setLocalSessionCost(e.target.value)}
          type="number"
          label="Cost per session(cent)"
          fullWidth
        />
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton onClick={() => saveCost()} variant="gradient" color="info" fullWidth>
          Save
        </MDButton>
      </MDBox>
    </DrawerRightRoot>
  );
}

export default CostDrawerInDetail;
