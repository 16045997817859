import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
// import CircleIcon from "@mui/icons-material/Circle";
import MDBox from "components/MDBox";
import {
  setCurrentEditCategoryName,
  setCurrentEditCategoryId,
  setODR,
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setCategoriesPageCount,
  setAllCategoriesData,
} from "context";
import { useState } from "react";
import { deleteCategory, getAllCategories } from "api/api_category";

export default function CategoryCard({ value, index }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [thisCategoryName, setThisCategoryName] = useState("");
  const [thisCategoryId, setThisCategoryId] = useState(0);
  const [open, setOpen] = React.useState(false);
  const editBTNClicked = (val) => {
    setODR(dispatch, "editCategory");
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
    setCurrentEditCategoryName(dispatch, val.name);
    setCurrentEditCategoryId(dispatch, val.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteBTNClicked = () => {
    handleClose();
    setCircularProgress(dispatch, true);
    deleteCategory((isOk, data) => {
      if (!isOk) {
        console.log("delete an category is not working", data);
        setCircularProgress(dispatch, false);
      } else {
        getAllCategories((isOk2, data2) => {
          if (!isOk2) {
            console.log("get categories data from api is not working", data2);
            setCircularProgress(dispatch, false);
          } else {
            setCategoriesPageCount(dispatch, data2.meta.last_page);
            setAllCategoriesData(dispatch, data2);
          }
          setCircularProgress(dispatch, false);
        }, window.location.search);
      }
    }, thisCategoryId);
  };

  const handleDialogOpen = (val) => {
    setOpen(true);
    setThisCategoryName(val.name);
    setThisCategoryId(val.id);
  };
  const getIsoString = (date) => {
    let currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    currentDate = currentDate.toISOString();
    return currentDate;
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Category Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              are you want to delete {thisCategoryName} category?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ backgroundColor: "#32cd32", color: "white" }} onClick={handleClose}>
              Disagree
            </Button>
            <Button
              style={{ backgroundColor: "#dc143c", color: "white" }}
              onClick={deleteBTNClicked}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div key={`${index}${darkMode}`}>
        <Card mt={2}>
          <CardContent>
            <MDBox
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography gutterBottom variant="h4" style={{ fontSize: "1rem" }} component="div">
                #{value.id}
              </Typography>
            </MDBox>
            <MDBox
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                color={value.emergencePriority === 0 ? "#FF0000" : "black"}
                style={{ fontSize: "1rem" }}
                component="div"
              >
                {value.name}
                {/* {value.emergencePriority === 0 && (
                  <CircleIcon className="text-red-500 ml-[5px] animate-ping" />
                )} */}
              </Typography>
            </MDBox>
            <MDBox
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography gutterBottom style={{ fontSize: "0.7rem" }} component="div">
                Create Time: {getIsoString(value.created_at).split("T")[0]}{" "}
                {getIsoString(value.created_at).split("T")[1].split(".")[0]}
              </Typography>
            </MDBox>
            <MDBox
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography gutterBottom style={{ fontSize: "0.7rem" }} component="div">
                Last Update: {getIsoString(value.updated_at).split("T")[0]}{" "}
                {getIsoString(value.updated_at).split("T")[1].split(".")[0]}
              </Typography>
            </MDBox>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              onClick={() => editBTNClicked(value)}
              size="small"
              style={{ color: "white" }}
              fullWidth
            >
              Edit
            </Button>
          </CardActions>
          <CardActions>
            <Button
              variant="contained"
              onClick={() => handleDialogOpen(value)}
              size="small"
              style={{ color: "white", backgroundColor: "#dc143c" }}
              fullWidth
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </div>
    </>
  );
}
// Setting default values for the props of CategoryCard
CategoryCard.defaultProps = {
  value: "",
  index: 1,
};

// Typechecking props of the CategoryCard
CategoryCard.propTypes = {
  value: PropTypes.object,
  index: PropTypes.number,
};
