/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { useMaterialUIController, setCircularProgress, setClientTab } from "context";
import { Grid, Icon } from "@mui/material";
import { getAClientSubscription } from "api/api_subscription";

import MDButton from "components/MDButton";
// import Billing from "layouts/billing";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

function Subscription({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const [subsData, setSubsData] = useState({});
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAClientSubscription((isOk, data) => {
      if (!isOk) {
        console.log("get client subscription from api is not working!", data);
        setCircularProgress(dispatch, false);
      } else {
        console.log("dispatch:  ", data);
        setSubsData(data);
        setCircularProgress(dispatch, false);
      }
    }, id);
  }, []);
  const getStatusColor = (value) => {
    switch (value) {
      case "active":
        return "bg-[#17d41a]";
      case "denied":
        return "bg-[#780b01]";
      case "pending":
        return "bg-[#bfd611]";
      default:
        return "bg-[#000d07]";
    }
  };
  if (Object.keys(subsData).length === 0)
    return (
      <div className="flex flex-col mb-2 items-center h-[200px] justify-center">
        <div>
          <Icon fontSize="large">emoji_symbols</Icon>
        </div>
        <div className="font-bold text-[20px]">No Subscription were found for this client </div>
        <MDButton
          onClick={() => setClientTab(dispatch, 1)}
          style={{ backgroundColor: "#0295E9", color: "white", marginTop: "20px" }}
        >
          Back To Oveview
        </MDButton>
      </div>
    );
  const getIsoString = (date) => {
    let currentDate = new Date(
      date.split(" ")[0].split("-")[0],
      parseInt(date.split(" ")[0].split("-")[1], 10) - 1,
      date.split(" ")[0].split("-")[2],
      date.split(" ")[1].split(":")[0],
      date.split(" ")[1].split(":")[1],
      date.split(" ")[1].split(":")[2]
    );
    currentDate = currentDate.toISOString();
    return currentDate;
  };
  return (
    <MDBox
      pb={3}
      style={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <div className="py-4 px-8 w-[100%]">
        <Grid container pt={1} pb={2} px={2}>
          <Grid item xs={12} className="p-[10px] flex items-center justify-center">
            <div
              style={{
                boxShadow: "0 0 20px grey",
                height: "300px",
                // display: "flex",
                borderRadius: "10px",
                width: "40%",
              }}
              className="flex flex-col justify-between"
            >
              <div className="flex bg-gray-400 flex items-center justify-center text-white rounded-t-md">
                Subscription is{" "}
                <div className={`${getStatusColor(subsData.status)} font-bold px-1 mx-1`}>
                  {subsData.status}
                </div>
              </div>
              {subsData.customer_id ? (
                <div className="flex justify-center">
                  <div className="mr-1">Customer id:</div>
                  <div>{subsData.customer_id}</div>
                </div>
              ) : (
                <div className="flex justify-center text-[30px] text-[#0295E9]">
                  <CardMembershipIcon />
                </div>
              )}
              <div className="flex">
                <div className="border-r border-r-[3px] w-[50%] flex flex-col items-center justify-center text-center">
                  <div className="text-[12px]">start of current period</div>
                  <div className="text-[30px] animate-bounce">
                    <ArrowDownwardIcon />
                  </div>
                  <div className="text-[16px] text-black">
                    {getIsoString(subsData.current_period_start).split("T")[0]}{" "}
                    {getIsoString(subsData.current_period_start).split("T")[1].split(".")[0]}
                  </div>
                </div>
                <div className="w-[50%] flex flex-col items-center justify-center text-center">
                  <div className="text-[12px]">start of current period</div>
                  <div className="text-[30px] animate-bounce">
                    <ArrowDownwardIcon />
                  </div>
                  <div className="text-[16px] text-black">
                    {getIsoString(subsData.current_period_end).split("T")[0]}{" "}
                    {getIsoString(subsData.current_period_end).split("T")[1].split(".")[0]}
                  </div>
                </div>
              </div>
              <div className="flex bg-gray-100 items-center justify-center text-[12px] border-t">
                <div>creation time: </div>
                <div>{subsData.created_at.split("T")[0]}</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </MDBox>
  );
}

export default Subscription;
