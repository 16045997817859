import { AxiosInstanceWithXAuthToken } from "./api";

export const getAdminsList = (callback, data) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins?${data}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getAppVersions = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/app-versions${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const postAppVersion = (callback, body) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/app-versions`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
