/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { updateAdminProfileAvatar } from "api/api_profile";
import { setCircularProgress, useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

function ProfileInfoCard({ title, description, info, social, action, shadow }) {
  const [controller, dispatch] = useMaterialUIController();
  const { profileData } = controller;
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  // Render the card social media icons
  const renderSocial = social.map(({ link, icon, color }) => (
    <MDBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </MDBox>
  ));
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState(profileData.first_name);
  const [lastName, setLastName] = useState(profileData.last_name);
  const [birthday, setBirthday] = useState(profileData.birthday);
  const [email, setEmail] = useState(profileData.email);
  const [gender, setGender] = useState(profileData.sex);
  useEffect(() => {
    setFirstName(profileData.first_name);
    setLastName(profileData.last_name);
    setBirthday(profileData.birthday);
    setEmail(profileData.email);
    setGender(profileData.sex);
  }, [profileData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const updateAvatar = () => {
    setOpen(false);
    const avatarFile = document.querySelector('input[name="avatar"]').files[0];
    const body = new FormData();
    body.append("first_name", firstName);
    body.append("last_name", lastName);
    body.append("email", email);
    body.append("birthday", birthday);
    body.append("sex", gender);
    if (avatarFile !== undefined && avatarFile !== "undefined" && avatarFile !== null) {
      body.append("avatar", avatarFile);
    }
    setCircularProgress(dispatch, true);
    updateAdminProfileAvatar(
      (isOk, data) => {
        if (!isOk) {
          console.log("update admin profile is unsuccessful", data);
          setCircularProgress(dispatch, false);
        } else {
          setCircularProgress(dispatch, false);
        }
      },
      profileData.id,
      body
    );
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Admin Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>Avatar</div>
            <input name="avatar" type="file" />
            <Divider />
            <MDBox mb={2}>
              <MDInput
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id="firstNameInput"
                type="text"
                label="First name"
                fullWidth
              />
            </MDBox>
            <Divider />
            <MDBox mb={2}>
              <MDInput
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id="lastNameInput"
                type="text"
                label="Last name"
                fullWidth
              />
            </MDBox>
            <Divider />
            <MDBox mb={2}>
              <MDInput
                value={birthday}
                label="Birthday"
                onChange={(e) => setBirthday(e.target.value)}
                id="date Input"
                type="date"
                fullWidth
              />
            </MDBox>
            <Divider />
            <MDBox mb={2}>
              <MDInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="emailInput"
                type="text"
                label="Email"
                fullWidth
              />
            </MDBox>
            <Divider />
            <MDBox>
              <MDTypography style={{ fontSize: "1rem" }}>Select Your gender</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <RadioGroup
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                row
                aria-labelledby="gender"
                name="gender"
                defaultValue="offline"
              >
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="male"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="female"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="other"
                  labelPlacement="start"
                />
              </RadioGroup>
            </MDBox>
            <Divider />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton style={{ backgroundColor: "#add8e6", color: "white" }} onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton
            style={{ backgroundColor: "#90ee90", color: "white" }}
            onClick={() => updateAvatar()}
          >
            Update
          </MDButton>
        </DialogActions>
      </Dialog>
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            onClick={() => handleClickOpen()}
            component={Link}
            to={action.route}
            variant="body2"
            color="secondary"
          >
            <Tooltip title={action.tooltip} placement="top">
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDBox mb={2} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox opacity={0.3}>
            <Divider />
          </MDBox>
          <MDBox>
            {renderItems}
            <MDBox display="flex" py={1} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                social: &nbsp;
              </MDTypography>
              {renderSocial}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
