import { AxiosInstanceWithXAuthToken } from "./api";

export const getLawyersList = (callback, data) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers?${data}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};

export const updateLawyerCost = (callback, body, lawyerId) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/lawyers/profile/${lawyerId}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};

export const approveLawyerStatus = (callback, lawyerId) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/users/approve/${lawyerId}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};

export const blockLawyerStatus = (callback, lawyerId) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/users/block/${lawyerId}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const createLawyer = (callback, body) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/lawyers`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const updateLawyer = (callback, body, lawyerId) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/lawyers/${lawyerId}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const updateLawyerCertificateStatus = (callback, body, lawyerId, certificateId) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/lawyers/${lawyerId}/certificates/${certificateId}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const updateLawyerOffice = (callback, body, lawyerId, certificateId) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/lawyers/${lawyerId}/offices/${certificateId}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getAllLawyerCertificates = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/${id}/certificates${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getLawyerActivities = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/activities/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getAllLawyerOffices = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/${id}/offices${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getALawyerDetails = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getLawyerRating = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/${id}/rating`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
