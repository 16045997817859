import { useEffect } from "react";

const AutoLogout = ({ children }) => {
  let timer;
  const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  let pageAccessedByReload =
    (performance.getEntriesByType("navigation")[0] &&
      performance.getEntriesByType("navigation")[0].type === 1) ||
    window.performance
      .getEntriesByType("navigation")
      .map((nav) => nav.type)
      .includes("reload");
  useEffect(() => {
    if (!pageAccessedByReload && window.location.pathname !== "/authentication/verify-number") {
      pageAccessedByReload = true;
      localStorage.clear();
      window.location.pathname = "/authentication/verify-number";
    }
  }, []);

  const logoutAction = () => {
    pageAccessedByReload = true;
    localStorage.clear();
    window.location.pathname = "/authentication/verify-number";
  };
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 600000);
  };
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  return children;
};
export default AutoLogout;
