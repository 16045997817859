import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { useMaterialUIController, setClientTab } from "context";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { transactionsData } = controller;
  function Author({ image, name, email, id }) {
    return (
      <Link
        onClick={() => {
          setClientTab(dispatch, 6);
        }}
        to={`/clients/detail/${id}`}
      >
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography
            style={{ minWidth: "50px" }}
            display="block"
            variant="button"
            fontWeight="medium"
          >
            #{id}
          </MDTypography>
          <MDAvatar src={image} name={name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
          </MDBox>
        </MDBox>
      </Link>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(transactionsData);
  }, [transactionsData]);
  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  const getPersonImage = (value) => {
    if (value === null || value.client === null || value.client.avatars === null) return person;
    return value.client.avatars.thumbnail;
  };
  const getIsoString = (date) => {
    let currentDate = new Date(
      date.split(" ")[0].split("-")[0],
      parseInt(date.split(" ")[0].split("-")[1], 10) - 1,
      date.split(" ")[0].split("-")[2],
      date.split(" ")[1].split(":")[0],
      date.split(" ")[1].split(":")[1],
      date.split(" ")[1].split(":")[2]
    );
    currentDate = currentDate.toISOString();
    return currentDate;
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      "client name": (
        <Author
          image={getPersonImage(value)}
          name={getFullName(
            value.client ? value.client.first_name : "",
            value.client ? value.client.last_name : ""
          )}
          email={value.client ? value.client.email : ""}
          id={value.client ? value.client.id : ""}
        />
      ),
      "total transferred money": <div>{Math.round((value.total_money / 100) * 100) / 100}$</div>,
      "transaction count": <div>{value.count}</div>,
      "last transaction time": (
        <div>
          {getIsoString(value.last_transaction_at).split("T")[0]}{" "}
          {getIsoString(value.last_transaction_at).split("T")[1].split(".")[0]}
        </div>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "client name", accessor: "client name", width: "45%", align: "left" },
      { Header: "total transferred money", accessor: "total transferred money", align: "center" },
      { Header: "transaction count", accessor: "transaction count", align: "center" },
      { Header: "last transaction time", accessor: "last transaction time", align: "center" },
    ],

    rows: getRows(),
  };
}
