// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// import PropTypes from "prop-types";
// Images
// import person from "assets/images/person.png";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

export default function data() {
  const [controller] = useMaterialUIController();
  const { transactionDetails } = controller;
  const [listOfData, setListOfData] = useState([]);
  useEffect(() => {
    if (transactionDetails.length > 0) {
      setListOfData(transactionDetails);
    } else {
      setListOfData([]);
    }
  }, [transactionDetails]);
  const getStatusColor = (value) => {
    switch (value) {
      case "success":
        return "#17d41a";
      case "denied":
        return "#780b01";
      case "pending":
        return "#bfd611";
      default:
        return "#000d07";
    }
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getIsoString = (date) => {
    let currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    currentDate = currentDate.toISOString();
    return currentDate;
  };
  const getRows = () => {
    const personsInfo = listOfData.map((value) => ({
      "pay time": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
            textAlign="center"
          >
            {parseInt(value.created_at.split("-")[2].split("T")[0], 10)}{" "}
            {monthNames[value.created_at.split("-")[1] - 1]} {value.created_at.split("-")[0]}
            <div className="mt-[5px]">
              {getIsoString(value.created_at).split("T")[1].split(".")[0]}
            </div>
          </MDTypography>
        </MDBox>
      ),
      amount: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {Math.round((value.amount / 100) * 100) / 100}$
          </MDTypography>
        </MDBox>
      ),
      "user balance": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.amount}
          </MDTypography>
        </MDBox>
      ),
      type: (
        <MDBox
          style={{
            fontSize: "0.7rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: getStatusColor(value.type),
          }}
        >
          {value.type}
        </MDBox>
      ),
      description: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.description}
          </MDTypography>
        </MDBox>
      ),
      "transaction id": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            #{value.transaction_id}
          </MDTypography>
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "transaction id", accessor: "transaction id", align: "left" },
      { Header: "amount", accessor: "amount", align: "center" },
      { Header: "description", accessor: "description", align: "center" },
      { Header: "type", accessor: "type", align: "center" },
      { Header: "user balance", accessor: "user balance", align: "center" },
      { Header: "pay time", accessor: "pay time", align: "center" },
    ],

    rows: getRows(),
  };
}
